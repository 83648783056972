.comments {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #E9ECEF;

    @media (min-width: $tablet) {
        padding-top: 60px;
        padding-bottom: 60px; 
    }

    @media (min-width: $desktop) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.comments__title {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 40px;

    & h2 {
        @include title;
        width: 77%;
    }

    @media (min-width: $tablet) {
        margin-bottom: 60px; 
    }

    @media (min-width: $desktop) {
        margin-bottom: 60px;

        & h2 {
            width: 65%; 
        }
    }
}

.comments__list {
    @include list-reset;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    padding: 0 12px;
    margin-bottom: 40px;

    @media (min-width: $tablet) {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px; 
        padding: 0;
    }
}

.comments__item {
    padding: 15px;
    font-size: 14px;
    line-height: 24px;
    background-color: #ffffff;

    @media (min-width: $tablet) {
        font-size: 16px; 
    }
}

.comments__text {
    margin: 0;

    & a {
        @include default-link;
        color: #842029;
    }
}

.comments__footnote {
    margin: 0;
    margin-bottom: 30px;
    font-size: 12px;
    color: #6F7174;

    @media (min-width: $tablet) {
        font-size: 14px; 
        margin-bottom: 50px;
    }

    @media (min-width: $desktop) {
        width: 70%;
    }
}
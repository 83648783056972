.lawyer-info {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: $tablet) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.lawyer-info__title {
    margin: 0;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 25px;
    text-transform: uppercase;

    @media (min-width: $tablet) {
        font-size: 40px;
    }
}

.lawyer-info__date {
    margin: 0;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;

    @media (min-width: $tablet) {
        font-size: 25px;
        margin-bottom: 30px;
    }
}

.lawyer-info__intro {
    margin: 0;
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 130%;
    text-indent: 20px;

    @media (min-width: $tablet) {
        font-size: 16px;
    }
}

.lawyer-info__text {
    margin: 0;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 130%;

    &--subclause {
        text-indent: 20px; 
    }

    @media (min-width: $tablet) {
        font-size: 16px;
    }
}

.lawyer-info__subtitle {
    margin-top: 40px;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;

    @media (min-width: $tablet) {
        font-size: 25px;
    }
}
.articles-news {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #E9ECEF;

    @media (min-width: $tablet) {
        padding-top: 80px;
        padding-bottom: 80px;  
    }

    @media (min-width: $desktop) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.articles-news__title {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 40px;

    & h2 {
        @include title;
        width: 65%;

        @media (min-width: $tablet) {
            font-size: 33px;
        }

        @media (min-width: $desktop) {
            font-size: 40px;
        }
    }

    @media (min-width: $desktop) {
        margin-bottom: 60px;
    }
}
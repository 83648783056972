.advatages {
    padding-top: 50px;
    padding-bottom: 40px;

    @media (min-width: $tablet) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width: $desktop) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.advatages__list {
    @include list-reset;

    @media (min-width: $tablet) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 25px;
    }
}

.advatages__item {
    margin-bottom: 30px;
    padding-left: 80px;
    background-repeat: no-repeat;
    background-position: center left;


    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    &--articles {
        background-image: url(../img/articles.svg);
    }

    &--law {
        background-image: url(../img/law.svg);
    }

    &--documents {
        background-image: url(../img/documents.svg);
    }

    &--map {
        background-image: url(../img/map.svg);
    }

    @media (min-width: $tablet) {
        margin-bottom: 0;
        padding-left: 0;
        padding-top: 80px;
        background-position: top center;
    }
}

.advatages__title {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;

    @media (min-width: $tablet) {
        margin-bottom: 20px;
        text-align: center;
    }
}

.advatages__text {
    font-size: 14px;
    margin: 0;

    @media (min-width: $tablet) {
        font-size: 16px;
        text-align: center;
    }
}
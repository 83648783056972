.publication {

    @media (min-width: $tablet) {
        display: flex;
    }

    @media (min-width: $desktop) {
        flex-direction: column;

        &--news {
            flex-direction: row;
        }
    }
}

.publication--news {
    & img {
        border-radius: 5px;
    }
}

.publication__img {
    display: block;
    width: 100%;
    height: auto;

    @media (min-width: $tablet) {
        width: 50%;
        height: auto;
    }

    @media (min-width: $desktop) {
        width: 100%;
    }
}

.publication__content {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #F8F9FA;
    border-radius: 4px;

    &--news {
        background-color: transparent;
    }

    @media (min-width: $desktop) {
        min-height: 220px;
        flex-grow: 1;
    }
}

.publication__title {
    @include title;
    display: block;
    margin-bottom: 15px;
    text-align: left;
    font-size: 18px;

    &--news {
        font-weight: 700;
    }

    @media (min-width: $tablet) {
        font-size: 25px;
    }
}

.publication__text {
    display: block;
    margin: 0;
    margin-bottom: 15px;
    line-height: 150%;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &--news {
        -webkit-line-clamp: 9; 
    }

    @media (min-width: $desktop) {
        &--news {
            font-size: 18px; 
        }
    }
}
.articles {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #E9ECEF;

    @media (min-width: $tablet) {
        padding-top: 80px;
        padding-bottom: 80px;  
    }

    @media (min-width: $desktop) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.articles__title {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 40px;

    & h2 {
        @include title;
        width: 65%;
    }

    @media (min-width: $desktop) {
        margin-bottom: 60px;
    }
}

.articles__list {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: $desktop) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 23px;
    }
}

.articles__item  {
    margin-bottom: 40px;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    @media (min-width: $tablet) {
        margin-bottom: 40px;
    }

    @media (min-width: $desktop) {
        margin-bottom: 0;
    }
}
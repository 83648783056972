.store {
    @media (min-width: $tablet) {
        display: grid;
        width: 80%;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
    }
}

.store__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    padding: 6px;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out;


    @media (min-width: $tablet) {
        margin-bottom: 0;
    }

    @media (min-width: $desktop) {
        padding: 6px 10px; 
    }

    &:hover {
        @media (hover: hover) {
            background-color: #FFC107;
            border: 1px solid #FFC107;
        }
    }

    &:active {
        background-color: #FFC107;
        border: 1px solid #FFC107;
    }
}

.store__link {
    @include link-reset;
    padding: 0 70px;
    color: #212529;

    & img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    @media (min-width: $tablet) {
        padding: 0 15px;
    }
}
.lawyer-header {
    padding-top: 30px;
    padding-bottom: 15px;
    background-color: #842029;

    @media (min-width: $tablet) {
        padding-top: 50px; 
        padding-bottom: 60px;
    }
}

.lawyer-header__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $tablet) {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        & a {
            display: flex;
        }
    }
}

.lawyer-header__img {
    display: block;
    margin-bottom: 50px; 

    @media (min-width: $tablet) {
        width: 165px;
        height: auto;
        margin: 0 auto;
        margin-right: 70px;
    }
}

.lawyer-header__list {
    @include list-reset;
    width: 100%;

    @media (min-width: $tablet) {
        width: 65%;
    }
}
@font-face {
  font-family: "PT Sans";
  src: url("../fonts/ptsans.woff2") format("woff2"),
    url("../fonts/ptsans.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "PT Sans";
  src: url("../fonts/ptsansbold.woff2") format("woff2"),
    url("../fonts/ptsansbold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}


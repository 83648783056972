.reference {
    @include list-reset;
}

.reference__item {
    margin-bottom: 15px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.reference__link {
    @include default-link;
    color: #212529;
    transition: color 0.2s ease-in-out;

    @media (min-width: $tablet) {
        text-align: right;
    }

    &:hover {
        @media (hover: hover) {
            color: #842029;
        }
    }

    &:active {  
        color: #842029;
    }
}
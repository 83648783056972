@mixin list-reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin button-reset {
    background-color: transparent;
    border: none;
}

@mixin button {
    @include button-reset;
    cursor: pointer;
}

@mixin link-reset {
    text-decoration: none;
}

@mixin default-link {
    @include link-reset;
}

@mixin default-title {
    margin: 0;
}

@mixin title {
    @include default-title;
    font-size: 20px;
    font-weight: 400;
    text-align: right;
    color: #212529;

    @media (min-width: $tablet) {
        font-size: 40px;
    }
}

@mixin wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}

@mixin wrapper-desktop {
    width: 100%;
    box-sizing: border-box;
    max-width: $wrapper-desktop;
    margin: 0 auto;
    padding: 0 42px;
}

@mixin wrapper-mobile {
    padding: 0 12px;
}

@mixin wrapper-tablet {
    padding: 0 36px;
}

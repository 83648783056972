@font-face {
  font-family: "PT Sans";
  src: url("../fonts/ptsans.woff2") format("woff2"), url("../fonts/ptsans.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PT Sans";
  src: url("../fonts/ptsansbold.woff2") format("woff2"), url("../fonts/ptsansbold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: 100%;
  min-width: 320px;
  font-family: "PT Sans", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: rgb(255, 255, 255);
  color: rgb(33, 37, 41);
}

.visually-hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.wrapper {
  padding: 0 12px;
}
@media (min-width: 768px) {
  .wrapper {
    padding: 0 36px;
  }
}
@media (min-width: 1200px) {
  .wrapper {
    width: 100%;
    box-sizing: border-box;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 42px;
  }
}

.btn {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 7px;
  margin-top: auto;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  border: none;
  background-color: #842029;
  border: 1px solid #842029;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
@media (min-width: 1200px) {
  .btn {
    padding: 10px;
    font-size: 16px;
  }
}
@media (hover: hover) {
  .btn:hover {
    color: #212529;
    background-color: transparent;
    border: 1px solid #842029;
  }
}
.btn:active {
  color: #212529;
  background-color: transparent;
  border: 1px solid #842029;
}
.btn:disabled {
  color: #ffffff;
  background-color: #842029;
  border: 1px solid #842029;
  opacity: 0, 65;
  cursor: auto;
}

.element {
  position: absolute;
  right: 0;
  left: 0;
  width: 35%;
  height: 5px;
  background-color: #FD7E14;
}
.element--events {
  width: 20%;
}
.element--new {
  left: auto;
  width: 20%;
}
.element--loyalty {
  left: auto;
  width: 23%;
}
.element--comments {
  width: 20%;
}
@media (min-width: 768px) {
  .element--events {
    width: 25%;
  }
  .element--new {
    width: 30%;
  }
  .element--loyalty {
    width: 30%;
  }
  .element--comments {
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .element {
    width: 50%;
  }
  .element--events {
    width: 50%;
  }
  .element--new {
    width: 50%;
  }
  .element--loyalty {
    width: 40%;
  }
  .element--comments {
    width: 35%;
  }
}

.page-header {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .page-header {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .page-header--news {
    padding-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .page-header--news {
    padding-bottom: 30px;
  }
}

.page-header__mail {
  text-decoration: none;
  padding: 6px 10px;
  color: #FFFFFF;
  border: 1px solid #FFC107;
  border-radius: 4px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .page-header__mail {
    padding: 10px 13px;
  }
}
@media (hover: hover) {
  .page-header__mail:hover {
    color: #212529;
    background-color: #FFC107;
  }
}
.page-header__mail:active {
  color: #212529;
  background-color: #FFC107;
}

.promo {
  position: relative;
  padding-top: 380px;
  padding-bottom: 25px;
}
.promo::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 115%;
  top: -101px;
  background-image: url("../img/img-start-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -100;
}
@media (min-width: 768px) {
  .promo {
    padding-top: 233px;
    padding-bottom: 60px;
  }
  .promo::before {
    height: 122%;
    top: -141px;
    background-image: url("../img/img-start.jpg");
    background-position: 90%;
  }
}
@media (min-width: 1200px) {
  .promo {
    padding-top: 130px;
    padding-bottom: 160px;
  }
  .promo::before {
    height: 123%;
    background-position: top;
  }
}

.promo__content img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .promo__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .promo__content img {
    width: 80%;
  }
}
@media (min-width: 1200px) {
  .promo__content {
    align-items: flex-start;
    width: 60%;
    height: auto;
  }
  .promo__content img {
    margin-left: 0;
  }
}

.promo__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 768px) {
  .store {
    display: grid;
    width: 80%;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
}

.store__item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding: 6px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  cursor: pointer;
  transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
@media (min-width: 768px) {
  .store__item {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .store__item {
    padding: 6px 10px;
  }
}
@media (hover: hover) {
  .store__item:hover {
    background-color: #FFC107;
    border: 1px solid #FFC107;
  }
}
.store__item:active {
  background-color: #FFC107;
  border: 1px solid #FFC107;
}

.store__link {
  text-decoration: none;
  padding: 0 70px;
  color: #212529;
}
.store__link img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .store__link {
    padding: 0 15px;
  }
}

.advatages {
  padding-top: 50px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .advatages {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .advatages {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.advatages__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .advatages__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
  }
}

.advatages__item {
  margin-bottom: 30px;
  padding-left: 80px;
  background-repeat: no-repeat;
  background-position: center left;
}
.advatages__item:nth-last-child(1) {
  margin-bottom: 0;
}
.advatages__item--articles {
  background-image: url(../img/articles.svg);
}
.advatages__item--law {
  background-image: url(../img/law.svg);
}
.advatages__item--documents {
  background-image: url(../img/documents.svg);
}
.advatages__item--map {
  background-image: url(../img/map.svg);
}
@media (min-width: 768px) {
  .advatages__item {
    margin-bottom: 0;
    padding-left: 0;
    padding-top: 80px;
    background-position: top center;
  }
}

.advatages__title {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
}
@media (min-width: 768px) {
  .advatages__title {
    margin-bottom: 20px;
    text-align: center;
  }
}

.advatages__text {
  font-size: 14px;
  margin: 0;
}
@media (min-width: 768px) {
  .advatages__text {
    font-size: 16px;
    text-align: center;
  }
}

.articles {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #E9ECEF;
}
@media (min-width: 768px) {
  .articles {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .articles {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.articles__title {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 40px;
}
.articles__title h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  text-align: right;
  color: #212529;
  width: 65%;
}
@media (min-width: 768px) {
  .articles__title h2 {
    font-size: 40px;
  }
}
@media (min-width: 1200px) {
  .articles__title {
    margin-bottom: 60px;
  }
}

.articles__list {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .articles__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 23px;
  }
}

.articles__item {
  margin-bottom: 40px;
}
.articles__item:nth-last-child(1) {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .articles__item {
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .articles__item {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .publication {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .publication {
    flex-direction: column;
  }
  .publication--news {
    flex-direction: row;
  }
}

.publication--news img {
  border-radius: 5px;
}

.publication__img {
  display: block;
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .publication__img {
    width: 50%;
    height: auto;
  }
}
@media (min-width: 1200px) {
  .publication__img {
    width: 100%;
  }
}

.publication__content {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #F8F9FA;
  border-radius: 4px;
}
.publication__content--news {
  background-color: transparent;
}
@media (min-width: 1200px) {
  .publication__content {
    min-height: 220px;
    flex-grow: 1;
  }
}

.publication__title {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  text-align: right;
  color: #212529;
  display: block;
  margin-bottom: 15px;
  text-align: left;
  font-size: 18px;
}
@media (min-width: 768px) {
  .publication__title {
    font-size: 40px;
  }
}
.publication__title--news {
  font-weight: 700;
}
@media (min-width: 768px) {
  .publication__title {
    font-size: 25px;
  }
}

.publication__text {
  display: block;
  margin: 0;
  margin-bottom: 15px;
  line-height: 150%;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.publication__text--news {
  -webkit-line-clamp: 9;
}
@media (min-width: 1200px) {
  .publication__text--news {
    font-size: 18px;
  }
}

.page-footer {
  margin-top: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #E9ECEF;
}
.page-footer--loyalty {
  background-color: #FFFFFF;
}
@media (min-width: 768px) {
  .page-footer {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .page-footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
}

.page-footer__logo {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.page-footer__logo img {
  display: block;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .page-footer__logo {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .page-footer__logo img {
    width: 110px;
    height: auto;
    margin-right: 20px;
  }
}

.page-footer__mail {
  text-decoration: none;
  font-weight: 700;
  color: #212529;
  transition: color 0.2s ease-in-out;
}
@media (hover: hover) {
  .page-footer__mail:hover {
    color: #842029;
  }
}
.page-footer__mail:active {
  color: #842029;
}

.page-footer__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .page-footer__list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.page-footer__item {
  margin-bottom: 15px;
}
.page-footer__item:nth-last-child(1) {
  margin-bottom: 0;
}

.page-footer__link {
  text-decoration: none;
  color: #212529;
  transition: color 0.2s ease-in-out;
}
.page-footer__link--contract, .page-footer__link--personal, .page-footer__link--consent {
  color: #842029;
}
@media (min-width: 768px) {
  .page-footer__link {
    text-align: right;
  }
}
@media (hover: hover) {
  .page-footer__link:hover {
    color: #842029;
  }
}
.page-footer__link:active {
  color: #842029;
}

.page-footer {
  color: #919ba5;
}
@media (min-width: 768px) {
  .page-footer {
    text-align: right;
  }
}

.events {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .events {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .events {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.events__title {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 40px;
}
.events__title h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  text-align: right;
  color: #212529;
  width: 77%;
}
@media (min-width: 768px) {
  .events__title h2 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .events__title {
    margin-bottom: 60px;
  }
}
@media (min-width: 1200px) {
  .events__title {
    margin-bottom: 60px;
  }
  .events__title h2 {
    width: 50%;
  }
}

.events__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .events__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  }
}
@media (min-width: 1200px) {
  .events__list {
    gap: 30px;
  }
}

.events__item {
  margin-bottom: 40px;
  padding: 15px;
  border: 3px solid #E9ECEF;
  border-radius: 4px;
}
.events__item:nth-last-child(1) {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .events__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  }
}

.info {
  background-color: #E9ECEF;
}

.info__text {
  margin: 0;
  font-size: 14px;
  line-height: 150%;
}
@media (min-width: 768px) {
  .info__text {
    font-size: 16px;
  }
}

.scores {
  background-color: #E9ECEF;
}
@media (min-width: 1200px) {
  .scores {
    justify-content: flex-end;
  }
}

.scores__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.scores__list::before {
  content: "";
  display: block;
  margin-bottom: 40px;
  width: 90px;
  height: 90px;
  background-image: url("../img/icon-star.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (min-width: 768px) {
  .scores__list::before {
    margin-bottom: 45px;
  }
}
@media (min-width: 1200px) {
  .scores__list::before {
    margin-bottom: 30px;
  }
}

.scores__item {
  margin-bottom: 10px;
  line-height: 150%;
}
.scores__item span {
  font-size: 14px;
  line-height: 150%;
  font-weight: 700;
  color: #FD7E14;
}
@media (min-width: 768px) {
  .scores__item {
    margin-bottom: 20px;
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .scores__item {
    margin-bottom: 10px;
  }
}

.footnote {
  border-color: #FD7E14;
}

.footnote__text {
  margin: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 150%;
}
.footnote__text:nth-last-child(1) {
  margin-bottom: 0;
}
.footnote__text a {
  color: #FD7E14;
}
@media (min-width: 768px) {
  .footnote__text {
    font-size: 16px;
  }
}

.new {
  padding: 40px 0;
  background-color: #E9ECEF;
}
@media (min-width: 768px) {
  .new {
    padding: 60px 0;
  }
}

.new__title {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
}
.new__title h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  text-align: right;
  color: #212529;
  font-weight: 700;
  text-align: left;
  width: 77%;
}
@media (min-width: 768px) {
  .new__title h2 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .new__title h2 {
    width: 70%;
  }
}
@media (min-width: 1200px) {
  .new__title {
    margin-bottom: 20px;
  }
  .new__title h2 {
    width: 45%;
  }
}

.new__text p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 150%;
}
@media (min-width: 768px) {
  .new__text p {
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .new__text p {
    width: 45%;
  }
}

.new__btn {
  padding: 0;
  color: #FD7E14;
  font-size: 14px;
  line-height: 150%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}
@media (min-width: 768px) {
  .new__btn {
    font-size: 16px;
  }
}
@media (hover: hover) {
  .new__btn:hover {
    color: #842029;
  }
}
.new__btn:active {
  color: #842029;
}

.send {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
}
.send::before {
  content: "";
  display: block;
  margin: auto;
  margin-bottom: 25px;
  width: 230px;
  height: 365px;
  background-image: url("../img/send-mob.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (min-width: 768px) {
  .send {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 60px;
    padding-bottom: 0;
  }
  .send::before {
    width: 50%;
    height: 275px;
    margin: 0;
    margin-bottom: 0;
    margin-right: 25px;
    background-image: url("../img/send-desk.png");
    background-position: top left;
  }
}
@media (min-width: 1200px) {
  .send {
    align-items: center;
  }
  .send::before {
    width: 450px;
    height: 400px;
  }
}

@media (min-width: 768px) {
  .send__content {
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .send__content {
    margin-bottom: 0;
  }
}

.send__text {
  margin: 0;
  margin-bottom: 20px;
  font-size: 20px;
}
@media (min-width: 768px) {
  .send__text {
    font-size: 32px;
  }
}
@media (min-width: 1200px) {
  .send__text {
    margin-bottom: 40px;
    font-size: 40px;
  }
}

.send__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .send__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}
@media (min-width: 1200px) {
  .send__list {
    width: 70%;
    gap: 20px;
  }
}

.send__item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding: 6px;
  border: 1px solid #842029;
  border-radius: 5px;
  cursor: pointer;
  transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.send__item:nth-last-child(1) {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .send__item {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .send__item {
    padding: 6px 10px;
  }
}
@media (hover: hover) {
  .send__item:hover {
    background-color: #FFC107;
    border: 1px solid #FFC107;
  }
}
.send__item:active {
  background-color: #FFC107;
  border: 1px solid #FFC107;
}

@media (min-width: 768px) {
  .send__link {
    padding: 0 15px;
  }
  .send__link img {
    display: block;
    width: 105px;
    height: auto;
  }
}

.modal-window {
  display: none;
  opacity: 0;
  transition: opacity 0.3s linear;
}

.modal-window__overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 150;
}

.modal-window__window {
  position: fixed;
  padding-top: 60px;
  padding-bottom: 30px;
  top: 50%;
  left: 50%;
  width: 82%;
  height: auto;
  transform: translate(-50%, -45%);
  transition: transform 0.3s linear;
  background-color: #FFFFFF;
  border: 5px solid #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 4px 24px 4px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
  z-index: 200;
}
@media (min-width: 768px) {
  .modal-window__window {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 80%;
  }
}
@media (min-width: 1200px) {
  .modal-window__window {
    width: 93%;
    overflow-y: auto;
    padding-bottom: 50px;
  }
}

.modal-window__svg {
  position: absolute;
  top: 20px;
  right: 18px;
  fill: #808080;
  transition: fill 0.3s ease;
  cursor: pointer;
}
@media (hover: hover) {
  .modal-window__svg:hover {
    fill: #212529;
  }
}
.modal-window__svg:active {
  fill: #212529;
}
@media (min-width: 768px) {
  .modal-window__svg {
    width: 35px;
    height: 35px;
    top: 40px;
    right: 38px;
  }
}
@media (min-width: 1200px) {
  .modal-window__svg {
    width: 40px;
  }
}

.modal-window__title {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.modal-window__title img {
  margin-right: 15px;
}
.modal-window__title h2 {
  font-weight: 400;
  font-size: 17px;
  margin: 0;
}
@media (min-width: 768px) {
  .modal-window__title h2 {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .modal-window__title {
    margin-bottom: 40px;
  }
  .modal-window__title h2 {
    font-size: 30px;
  }
  .modal-window__title img {
    width: 55px;
    height: 55px;
    margin-right: 20px;
  }
}

.modal-window__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .modal-window__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}
@media (min-width: 1200px) {
  .modal-window__list {
    width: 70%;
    gap: 20px;
  }
}

.modal-window__item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  padding: 8px;
  border: 1px solid #842029;
  border-radius: 5px;
  cursor: pointer;
  transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.modal-window__item:nth-last-child(1) {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .modal-window__item {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .modal-window__item {
    padding: 8px 10px;
  }
}
@media (hover: hover) {
  .modal-window__item:hover {
    background-color: #FFC107;
    border: 1px solid #FFC107;
  }
}
.modal-window__item:active {
  background-color: #FFC107;
  border: 1px solid #FFC107;
}

.modal-window__link {
  display: flex;
  align-items: center;
  padding: 0 70px;
}
@media (min-width: 768px) {
  .modal-window__link {
    padding: 0 50px;
  }
  .modal-window__link img {
    display: block;
    width: 105px;
    height: auto;
  }
}

.modal-window.open {
  opacity: 1;
}
.modal-window.open .modal-window__window {
  transform: translate(-50%, -50%);
}

.lawyer-header {
  padding-top: 30px;
  padding-bottom: 15px;
  background-color: #842029;
}
@media (min-width: 768px) {
  .lawyer-header {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

.lawyer-header__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .lawyer-header__content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
  .lawyer-header__content a {
    display: flex;
  }
}

.lawyer-header__img {
  display: block;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .lawyer-header__img {
    width: 165px;
    height: auto;
    margin: 0 auto;
    margin-right: 70px;
  }
}

.lawyer-header__list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
@media (min-width: 768px) {
  .lawyer-header__list {
    width: 65%;
  }
}

.lawyer-info {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .lawyer-info {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.lawyer-info__title {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 25px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .lawyer-info__title {
    font-size: 40px;
  }
}

.lawyer-info__date {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
}
@media (min-width: 768px) {
  .lawyer-info__date {
    font-size: 25px;
    margin-bottom: 30px;
  }
}

.lawyer-info__intro {
  margin: 0;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 130%;
  text-indent: 20px;
}
@media (min-width: 768px) {
  .lawyer-info__intro {
    font-size: 16px;
  }
}

.lawyer-info__text {
  margin: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 130%;
}
.lawyer-info__text--subclause {
  text-indent: 20px;
}
@media (min-width: 768px) {
  .lawyer-info__text {
    font-size: 16px;
  }
}

.lawyer-info__subtitle {
  margin-top: 40px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .lawyer-info__subtitle {
    font-size: 25px;
  }
}

.promo-news {
  position: relative;
}
.promo-news::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 215%;
  top: -101px;
  background-image: url("../img/img-start-news-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -100;
}
@media (min-width: 768px) {
  .promo-news::before {
    height: 182%;
    top: -141px;
    background-image: url("../img/img-start-news-tablet.jpg");
    background-position: 90%;
  }
}
@media (min-width: 1200px) {
  .promo-news::before {
    height: 210%;
    background-position: top;
  }
}

.promo-news__content img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .promo-news__content {
    display: flex;
    align-items: center;
  }
  .promo-news__content img {
    width: 110%;
  }
}
@media (min-width: 1200px) {
  .promo-news__content {
    align-items: flex-start;
    width: 60%;
    height: auto;
    margin-bottom: 40px;
  }
  .promo-news__content img {
    width: 135%;
    margin-left: 0;
    margin-bottom: 0;
  }
}

.articles-news {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #E9ECEF;
}
@media (min-width: 768px) {
  .articles-news {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .articles-news {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.articles-news__title {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 40px;
}
.articles-news__title h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  text-align: right;
  color: #212529;
  width: 65%;
}
@media (min-width: 768px) {
  .articles-news__title h2 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .articles-news__title h2 {
    font-size: 33px;
  }
}
@media (min-width: 1200px) {
  .articles-news__title h2 {
    font-size: 40px;
  }
}
@media (min-width: 1200px) {
  .articles-news__title {
    margin-bottom: 60px;
  }
}

.loyalty {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .loyalty {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 1200px) {
  .loyalty {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.loyalty__title {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
}
.loyalty__title h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  text-align: right;
  color: #212529;
  text-align: left;
  width: 77%;
}
@media (min-width: 768px) {
  .loyalty__title h2 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .loyalty__title {
    margin-bottom: 8px;
  }
  .loyalty__title h2 {
    width: 70%;
  }
}
@media (min-width: 1200px) {
  .loyalty__title {
    margin-bottom: 10px;
  }
  .loyalty__title h2 {
    width: 60%;
  }
}

.loyalty__subtitle {
  margin: 0;
  margin-bottom: 20px;
  font-size: 14px;
}
@media (min-width: 768px) {
  .loyalty__subtitle {
    margin-bottom: 40px;
    font-size: 16px;
  }
}

.loyalty__content {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .loyalty__content {
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .loyalty__content {
    margin-bottom: 0;
  }
}

.loyalty__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  padding-bottom: 346px;
  background-image: url("../img/loyalty-bg.png");
  background-size: 261px auto;
  background-repeat: no-repeat;
  background-position: bottom center;
}
@media (min-width: 768px) {
  .loyalty__list {
    gap: 20px;
    padding-bottom: 0;
    padding-right: 345px;
    background-size: auto 500px;
    background-position: center right;
  }
}
@media (min-width: 1200px) {
  .loyalty__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding-top: 60px;
    padding-bottom: 60px;
    background-size: 336px auto;
  }
}

.loyalty__item {
  padding: 15px 0 15px 75px;
  font-size: 14px;
  line-height: 20px;
  background-image: url("../img/icon-star.svg");
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: left center;
}
@media (min-width: 768px) {
  .loyalty__item {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
}

.loyalty__text {
  margin: 0;
}
.loyalty__text span {
  font-weight: 700;
  color: #F9A323;
}

.loyalty__footnote {
  margin: 0;
  font-size: 12px;
  color: #6F7174;
}
@media (min-width: 768px) {
  .loyalty__footnote {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .loyalty__footnote {
    width: 60%;
  }
}

.comments {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #E9ECEF;
}
@media (min-width: 768px) {
  .comments {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 1200px) {
  .comments {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.comments__title {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 40px;
}
.comments__title h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  text-align: right;
  color: #212529;
  width: 77%;
}
@media (min-width: 768px) {
  .comments__title h2 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .comments__title {
    margin-bottom: 60px;
  }
}
@media (min-width: 1200px) {
  .comments__title {
    margin-bottom: 60px;
  }
  .comments__title h2 {
    width: 65%;
  }
}

.comments__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  padding: 0 12px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .comments__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 0;
  }
}

.comments__item {
  padding: 15px;
  font-size: 14px;
  line-height: 24px;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .comments__item {
    font-size: 16px;
  }
}

.comments__text {
  margin: 0;
}
.comments__text a {
  text-decoration: none;
  color: #842029;
}

.comments__footnote {
  margin: 0;
  margin-bottom: 30px;
  font-size: 12px;
  color: #6F7174;
}
@media (min-width: 768px) {
  .comments__footnote {
    font-size: 14px;
    margin-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .comments__footnote {
    width: 70%;
  }
}

.reference {
  margin: 0;
  padding: 0;
  list-style: none;
}

.reference__item {
  margin-bottom: 15px;
}
.reference__item:nth-last-child(1) {
  margin-bottom: 0;
}

.reference__link {
  text-decoration: none;
  color: #212529;
  transition: color 0.2s ease-in-out;
}
@media (min-width: 768px) {
  .reference__link {
    text-align: right;
  }
}
@media (hover: hover) {
  .reference__link:hover {
    color: #842029;
  }
}
.reference__link:active {
  color: #842029;
}
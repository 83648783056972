.page-footer {
  margin-top: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #E9ECEF;

  &--loyalty {
    background-color: #FFFFFF;
  }

  @media (min-width: $tablet) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.page-footer__container {
  @media (min-width: $tablet) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
}

.page-footer__logo {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  & img {
    display: block;
    margin-right: 10px;
  }

  @media (min-width: $tablet) {
    margin-bottom: 0;
  }

  @media (min-width: $desktop) {
    & img {
      width: 110px;
      height: auto;
      margin-right: 20px;
    }
  }
}

.page-footer__mail {
  @include default-link;
  font-weight: 700;
  color: #212529;
  transition: color 0.2s ease-in-out;

  &:hover {
    @media (hover: hover) {
      color: #842029;
    }
  }

  &:active {
    color: #842029;
  }
}

.page-footer__list {
  @include list-reset;
  margin-bottom: 30px;

  @media (min-width: $tablet) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.page-footer__item {
  margin-bottom: 15px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.page-footer__link {
  @include default-link;
  color: #212529;
  transition: color 0.2s ease-in-out;

  &--contract,
  &--personal,
  &--consent {
    color: #842029;
  }

  @media (min-width: $tablet) {
    text-align: right;
  }

  &:hover {
    @media (hover: hover) {
      color: #842029;
    }
  }

  &:active {  
    color: #842029;
  }
}

.page-footer {
  color: #919ba5;

  @media (min-width: $tablet) {
    text-align: right;
  }
}
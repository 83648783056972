.footnote {
    border-color: #FD7E14;
}

.footnote__text {
    margin: 0;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 150%;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    & a {
        color: #FD7E14;
    }

    @media (min-width: $tablet) {
        font-size: 16px; 
    }
}
.page-header {
    padding-top: 40px;
    padding-bottom: 40px;

    @media (min-width: $tablet) {
        padding-top: 60px;
        padding-bottom: 60px;

        &--news {
            padding-bottom: 0;  
        }
    }

    @media (min-width: $desktop) {
        &--news {
            padding-bottom: 30px;  
        } 
    }
}

.page-header__mail {
    @include default-link;
    padding: 6px 10px;
    color: #FFFFFF;
    border: 1px solid #FFC107;
    border-radius: 4px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    @media (min-width: $tablet) {
        padding: 10px 13px;
    }

    &:hover {
        @media (hover: hover) {
            color: #212529;
            background-color: #FFC107;
        }
    }

    &:active {
        color: #212529;
        background-color: #FFC107;
    }
}

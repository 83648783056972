.info {
    background-color: #E9ECEF;
}

.info__text {
    margin: 0;
    font-size: 14px;
    line-height: 150%;

    @media (min-width: $tablet) {
        font-size: 16px; 
    }
}
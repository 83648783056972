html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: 100%;
  min-width: 320px;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 400;
  background-color: $body-color;
  color: $fonts-color;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.wrapper {
  @include wrapper-mobile;

  @media (min-width: $tablet) {
    @include wrapper-tablet;
  }

  @media (min-width: $desktop) {
    @include wrapper-desktop;
  }
}

.btn {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 7px;
  margin-top: auto;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  border: none;
  background-color: #842029;
  border: 1px solid #842029;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  @media (min-width: $desktop) {
    padding: 10px;
    font-size: 16px;
  }

  &:hover {
    @media (hover: hover) {
      color: #212529;
      background-color: transparent;
      border: 1px solid #842029;
    }
  }

  &:active {
    color: #212529;
    background-color: transparent;
    border: 1px solid #842029;
  }

  &:disabled {
    color: #ffffff;
    background-color: #842029;
    border: 1px solid #842029;
    opacity: 0,65;
    cursor: auto;
  }
}

.element {
  position: absolute;
  right: 0;
  left: 0;
  width: 35%;
  height: 5px;
  background-color: #FD7E14;

  &--events {
    width: 20%;
  }

  &--new {
    left: auto;
    width: 20%;
  }

  &--loyalty {
    left: auto;
    width: 23%;
  }

  &--comments {
    width: 20%;
  }

  @media (min-width: $tablet) {
    
    &--events {
      width: 25%;
    }

    &--new {
      width: 30%;
    }

    &--loyalty {
      width: 30%;
    }

    &--comments {
      width: 25%;
    }
  }

  @media (min-width: $desktop) {
    width: 50%;

    &--events {
      width: 50%;
    }

    &--new {
      width: 50%;
    }

    &--loyalty {
      width: 40%;
    }

    &--comments {
      width: 35%;
    }
  }
}

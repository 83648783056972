.loyalty {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #ffffff;

    @media (min-width: $tablet) {
        padding-top: 60px;
        padding-bottom: 60px; 
    }

    @media (min-width: $desktop) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.loyalty__title {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;

    & h2 {
        @include title;
        text-align: left;
        width: 77%;
    }

    @media (min-width: $tablet) {
        margin-bottom: 8px;
        
        & h2 {
            width: 70%;  
        }  
    }

    @media (min-width: $desktop) {
        margin-bottom: 10px;

        & h2 {
            width: 60%; 
        }
    }
}

.loyalty__subtitle {
    margin: 0;
    margin-bottom: 20px;
    font-size: 14px;

    @media (min-width: $tablet) {
        margin-bottom: 40px;
        font-size: 16px;
    }
}

.loyalty__content {
    margin-bottom: 20px;

    @media (min-width: $tablet) {
        margin-bottom: 40px; 
    }

    @media (min-width: $desktop) {
        margin-bottom: 0;
    }
}

.loyalty__list {
    @include list-reset;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    padding-bottom: 346px;
    background-image: url("../img/loyalty-bg.png");
    background-size: 261px auto;
    background-repeat: no-repeat;
    background-position: bottom center;

    @media (min-width: $tablet) {
        gap: 20px; 
        padding-bottom: 0;
        padding-right: 345px;
        background-size: auto 500px;
        background-position: center right;
    }

    @media (min-width: $desktop)  {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px; 
        padding-top: 60px;
        padding-bottom: 60px;
        background-size: 336px auto;
    }
}

.loyalty__item {
    padding: 15px 0 15px 75px;
    font-size: 14px;
    line-height: 20px;
    background-image: url("../img/icon-star.svg");
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: left center;

    @media (min-width: $tablet) {
        display: flex;
        align-items: center;
        font-size: 16px;  
    }
}

.loyalty__text {
    margin: 0;

    & span {
        font-weight: 700;
        color: #F9A323;
    }
}

.loyalty__footnote {
    margin: 0;
    font-size: 12px;
    color: #6F7174;

    @media (min-width: $tablet) {
        font-size: 14px; 
    }

    @media (min-width: $desktop) {
        width: 60%;
    }
}
.modal-window {
    display: none;
    opacity: 0;
    transition: opacity 0.3s linear;
}

.modal-window__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.2);
    z-index: 150;
}

.modal-window__window {
    position: fixed;
    padding-top: 60px;
    padding-bottom: 30px;
    top: 50%;
    left: 50%;
    width: 82%;
    height: auto;

    transform: translate(-50%, -45%);
    transition: transform 0.3s linear;

    background-color: #FFFFFF;
    border: 5px solid #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 4px 24px 4px rgba(0, 0, 0, 0.25);
    overflow-y: scroll;
    z-index: 200;

    @media (min-width: $tablet) {
        padding-top: 40px;
        padding-bottom: 40px;
        width: 80%;
    }

    @media (min-width: $desktop) {
        width: 93%;
        overflow-y: auto;
        padding-bottom: 50px;
    }
}

.modal-window__svg {
    position: absolute;
    top: 20px;
    right: 18px;
    fill: #808080;
    transition: fill 0.3s ease;
    cursor: pointer;

    &:hover {
        @media (hover: hover) {
            fill: #212529;
        }
    }

    &:active {
        fill: #212529;
    }

    @media (min-width: $tablet) {
        width: 35px;
        height: 35px;
        top: 40px;
        right: 38px;
    }

    @media (min-width: $desktop) {
        width: 40px;
    }
}

.modal-window__title {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    & img {
        margin-right: 15px;
    }

    & h2 {
        font-weight: 400;
        font-size: 17px;
        margin: 0;
    }

    @media (min-width: $tablet) {
        & h2 {
            font-size: 20px; 
        } 
    }

    @media (min-width: $desktop) {
        margin-bottom: 40px;

        & h2 {
            font-size: 30px;  
        }

        & img {
            width: 55px;
            height: 55px;
            margin-right: 20px;
        }
    }
}

.modal-window__list {
    @include list-reset;

    @media (min-width: $tablet) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
    }

    @media (min-width: $desktop) {
        width: 70%;
        gap: 20px;
    }
}

.modal-window__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    padding: 8px;
    border: 1px solid #842029;
    border-radius: 5px;
    cursor: pointer;
    transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    @media (min-width: $tablet) {
        margin-bottom: 0;
    }

    @media (min-width: $desktop) {
        padding: 8px 10px; 
    }

    &:hover {
        @media (hover: hover) {
            background-color: #FFC107;
            border: 1px solid #FFC107;
        }
    }

    &:active {
        background-color: #FFC107;
        border: 1px solid #FFC107;
    }
}

.modal-window__link {
    display: flex;
    align-items: center;
    padding: 0 70px;

    @media (min-width: $tablet) {
        padding: 0 50px;
        
        & img {
            display: block;
            width: 105px;
            height: auto;
        }
    }
}

.modal-window.open {
    opacity: 1;

    & .modal-window__window {
        transform: translate(-50%, -50%);
    }
}
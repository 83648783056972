.promo-news {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 215%;
        top: -101px;
        background-image: url("../img/img-start-news-mobile.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -100;
    }

    @media (min-width: $tablet) {

        &::before {
            height: 182%;
            top: -141px;
            background-image: url("../img/img-start-news-tablet.jpg");
            background-position: 90%;
        }
    }

    @media (min-width: $desktop) {

        &::before {
            height: 210%;
            background-position: top;
        }
    }
}

.promo-news__content {

    & img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    @media (min-width: $tablet) {
        display: flex;
        align-items: center;

        & img {
            width: 110%;
        }
    }

    @media (min-width: $desktop) {
        align-items: flex-start;
        width: 60%;
        height: auto;
        margin-bottom: 40px;

        & img {
            width: 135%;
            margin-left: 0;
            margin-bottom: 0; 
        }
    }
}
.promo {
    position: relative;
    padding-top: 380px;
    padding-bottom: 25px;

    &::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 115%;
        top: -101px;
        background-image: url("../img/img-start-mobile.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -100;
    }

    @media (min-width: $tablet) {
        padding-top: 233px;
        padding-bottom: 60px;

        &::before {
            height: 122%;
            top: -141px;
            background-image: url("../img/img-start.jpg");
            background-position: 90%;
        }
    }

    @media (min-width: $desktop) {
        padding-top: 130px;
        padding-bottom: 160px;

        &::before {
            height: 123%;
            background-position: top;
        }
    }
}

.promo__content {

    & img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    @media (min-width: $tablet) {
        display: flex;
        flex-direction: column;
        align-items: center;

        & img {
            width: 80%;
        }
    }

    @media (min-width: $desktop) {
        align-items: flex-start;
        width: 60%;
        height: auto;

        & img {
            margin-left: 0; 
        }
    }
}

.promo__list {
    @include list-reset;
}
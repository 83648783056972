//font
$font-family: "PT Sans", "Arial", sans-serif;

//viewports
$tablet: 768px;
$desktop: 1200px;
$mobile-only: 767px;

//color
$body-color: rgba(255, 255, 255, 1);
$fonts-color: rgba(33, 37, 41, 1);

//padding
$padding-mobile: 0 12px;
$padding-tablet: 0 36px;
$padding-desktop: 0 42px;

//wrapper
$wrapper-desktop: 1400px;

.events {
    padding-top: 40px;
    padding-bottom: 40px;

    @media (min-width: $tablet) {
        padding-top: 80px;
        padding-bottom: 80px; 
    }

    @media (min-width: $desktop) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.events__title {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 40px;

    & h2 {
        @include title;
        width: 77%;
    }

    @media (min-width: $tablet) {
        margin-bottom: 60px; 
    }

    @media (min-width: $desktop) {
        margin-bottom: 60px;

        & h2 {
            width: 50%; 
        }
    }
}

.events__list {
    @include list-reset;

    @media (min-width: $tablet) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;
    }

    @media (min-width: $desktop) {
        gap: 30px;
    }
}

.events__item {
    margin-bottom: 40px;
    padding: 15px;
    border: 3px solid #E9ECEF;
    border-radius: 4px;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    @media (min-width: $tablet) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
    }
}
.send {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;

    &::before {
        content: "";
        display: block;
        margin: auto;
        margin-bottom: 25px;
        width: 230px;
        height: 365px;
        background-image: url("../img/send-mob.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    @media (min-width: $tablet) {
        flex-direction: row;
        justify-content: space-between;
        padding-top: 60px;
        padding-bottom: 0;

        &::before {
            width: 50%;
            height: 275px;
            margin: 0;
            margin-bottom: 0;
            margin-right: 25px;
            background-image: url("../img/send-desk.png");
            background-position: top left;  
        }
    }

    @media (min-width: $desktop) {
        align-items: center;

        &::before {
            width: 450px; 
            height: 400px;
        }
    }
}

.send__content {
    @media (min-width: $tablet) {
        margin-bottom: 30px;
    }

    @media (min-width: $desktop) {
        margin-bottom: 0;
    }
}

.send__text {
    margin: 0;
    margin-bottom: 20px;
    font-size: 20px;

    @media (min-width: $tablet) {
        font-size: 32px;
    }

    @media (min-width: $desktop) {
        margin-bottom: 40px;
        font-size: 40px;
    }
}

.send__list {
    @include list-reset;

    @media (min-width: $tablet) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
    }

    @media (min-width: $desktop) {
        width: 70%;
        gap: 20px;
    }
}

.send__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    padding: 6px;
    border: 1px solid #842029;
    border-radius: 5px;
    cursor: pointer;
    transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    @media (min-width: $tablet) {
        margin-bottom: 0;
    }

    @media (min-width: $desktop) {
        padding: 6px 10px; 
    }

    &:hover {
        @media (hover: hover) {
            background-color: #FFC107;
            border: 1px solid #FFC107;
        }
    }

    &:active {
        background-color: #FFC107;
        border: 1px solid #FFC107;
    }
}

.send__link {

    @media (min-width: $tablet) {
        padding: 0 15px;
        
        & img {
            display: block;
            width: 105px;
            height: auto;
        }
    }
}
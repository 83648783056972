.scores {
    background-color: #E9ECEF;

    @media (min-width: $desktop) {
        justify-content: flex-end;
    }
}

.scores__list {
    @include list-reset;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &::before {
        content: "";
        display: block;
        margin-bottom: 40px;
        width: 90px;
        height: 90px;
        background-image: url("../img/icon-star.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    @media (min-width: $tablet) {
        &::before {
            margin-bottom: 45px;  
        }
    }

    @media (min-width: $desktop) {
        &::before {
            margin-bottom: 30px;
        }
    }
}

.scores__item {
    margin-bottom: 10px;
    line-height: 150%;

    & span {
        font-size: 14px;
        line-height: 150%;
        font-weight: 700;
        color: #FD7E14;
    }

    @media (min-width: $tablet) {
        margin-bottom: 20px;
        font-size: 16px;
    }

    @media (min-width: $desktop) {
        margin-bottom: 10px;
    }
}
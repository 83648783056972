.new {
    padding: 40px 0;
    background-color: #E9ECEF;
    
    @media (min-width: $tablet) {
        padding: 60px 0; 
    }
}

.new__title {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;

    & h2 {
        @include title;
        font-weight: 700;
        text-align: left;
        width: 77%;
    }

    @media (min-width: $tablet) {
        & h2 {
            width: 70%;  
        }  
    }

    @media (min-width: $desktop) {
        margin-bottom: 20px;

        & h2 {
            width: 45%; 
        }
    }
}

.new__text {
    & p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 150%;
    }

    @media (min-width: $tablet) {
        & p {
            font-size: 16px; 
        }
    }

    @media (min-width: $desktop) {
        & p {
            width: 45%;
        }
    }
}

.new__btn {
    padding: 0;
    color: #FD7E14;
    font-size: 14px;
    line-height: 150%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    @media (min-width: $tablet) {
        font-size: 16px; 
    }

    &:hover {
        @media (hover: hover) {
            color: #842029;
        }
    }

    &:active {
        color: #842029;
    }
}